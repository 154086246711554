import { gql } from "@apollo/client"

export const ARTICLE_LIST = gql`
  query articles($category: String) {
    articles(publish: 1, category: $category) {
      collection {
        id
        name
        img
        prologue
      }
    }
  }
`
