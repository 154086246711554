import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { useQuery } from "@apollo/client"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"
import { ARTICLE_LIST } from "../query/article"
import { Loading } from "../components/layout/Loading"

const JakPracujemePage = () => {
  const { loading, error, data } = useQuery(ARTICLE_LIST, {
    variables: {
      category: "nas-tym",
    },
    fetchPolicy: "cache-and-network",
  })
  const articles = data ? data?.articles?.collection : []

  function returnText(text, lineNumber) {
    var str = text.split(/\n/)
    return str[lineNumber]
  }

  return (
    <>
      {loading && <Loading loading={true} />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Náš tým | Holding Malgen</title>
      </Helmet>
      <main className="pb-8">
        {/* Hero card */}
        <div className="relative h-96">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-200" />
          <StaticImage
            src="../images/dum.jpg"
            className="h-full w-full absolute object-cover border border-gray-200 "
            alt="Malgen"
          />
          <div className="absolute bottom-0  sm:overflow-hidden w-full border-t border-gray-400">
            <div className="absolute inset-0 bottom-0">
              <div className="absolute inset-0 bg-gray-300 mix-blend-multiply backdrop-filter backdrop-blur-sm" />
            </div>
            <div className="relative px-4 max-w-7xl mx-auto">
              <h1 className="text-center  text-2xl lg:text-3xl grid py-6 px-3 ">
                <span className=" text-white">Náš tým</span>
                <span className=" text-white text-2xl"></span>
              </h1>
            </div>
          </div>
        </div>
        <div className="mt-10 pb-12 bg-white sm:pb-16 grid lg:grid-cols-2 gap-3 max-w-5xl mx-auto px-8">
          {articles.map((article, i) => (
            <div
              key={i}
              className="space-y-12 my-10 space-x-2  lg:mx-auto overflow-hidden"
            >
              <div>
                <div className="flex space-x-4 ">
                  <div className=" overflow-hidden w-40  h-52 flex rounded-lg">
                    <img
                      src={article.img}
                      layout="fixed"
                      height={200}
                      className="  object-cover "
                      alt={article.name}
                    />
                  </div>

                  <div className="space-y-2">
                    <div className="text-lg leading-6 p-6 md:px-0 lg:p-0 font-medium space-y-2">
                      <div className=" items-center text-base ">
                        <h3 className="text-xl text-blue-600 font-semibold">
                          {article.name}
                        </h3>
                        <h2 className="text-xs text-gray-400 font-semibold tracking-wide uppercase">
                          realitní makléř
                        </h2>
                      </div>
                      <div className=" flex items-center text-base ">
                        <PhoneIcon
                          className="h-4 w-4 text-gray-400 mr-3"
                          aria-hidden="true"
                        />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: returnText(article.prologue, 0),
                          }}
                        ></span>
                      </div>
                      <div className=" flex items-center text-base ">
                        <MailIcon
                          className="h-4 w-4 text-gray-400 mr-3"
                          aria-hidden="true"
                        />
                        <span
                          rel="noreferrer"
                          dangerouslySetInnerHTML={{
                            __html: returnText(article.prologue, 1),
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    </>
  )
}

export default JakPracujemePage
